<template>
  <WeContainer card="">
    <WeCard class="mb-3 sticky-top shadow-sm">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="custom-card-title">
            <i class="fas fa-cube mr-2"></i>
            <span>Toplu Ürün Güncelle</span>
          </h5>
        </div>
        <div class="col-auto">
          <!-- Submit -->
          <WeSubmitButton
            update="1"
            parent-class="text-center"
            v-bind:submit-status="submit"
            v-bind:permission="checkPermission"
            v-bind:disabled="submitDisabled"
            v-on:submit="submitForm"
          />
          <!-- ./Submit -->
        </div>
      </div>
    </WeCard>

    <!-- Filter -->
    <WeCard class="mb-3">
      <h5 class="custom-card-title">
        <i class="fas fa-filter mr-3"></i>Filtreleme
      </h5>
      <hr />
      <div class="row">
        <div class="col-12 col-lg-6">
          <!-- Name -->
          <div class="d-block mb-3">
            <WeRowInput
              size="sm"
              name="product"
              label="Ürün"
              placeholder="Ürün Adı"
              v-model="filter.name"
            />
          </div>
          <!-- Name -->

          <!-- Category -->
          <div class="d-block mb-3">
            <WeRowInput
              form="vMultipleSelect"
              size="sm"
              name="category"
              label="Kategori"
              placeholder="Kategori"
              v-bind:option-prop="categoryList"
              v-model="filter.category"
            />
          </div>
          <!-- Category -->

          <!-- Brand -->
          <div class="d-block mb-3">
            <WeRowInput
              form="searchSelect"
              size="sm"
              name="brand"
              label="Marka"
              placeholder="Marka"
              v-model="filter.brand"
              v-bind:clearable="true"
            />
          </div>
          <!-- Brand -->

          <div class="d-block mb-3">
            <WeRowInput
              form="vMultipleSelect"
              size="sm"
              name="supplier"
              label="Tedarikçi"
              placeholder="Tedarikçi"
              v-bind:option-prop="suppliers"
              v-model="filter.supplier"
            />
          </div>

          <!-- Marketplace -->
          <div class="d-block mb-3">
            <WeRowInput
              form="vSelect"
              size="sm"
              name="marketplace"
              label="Pazaryeri"
              placeholder="Pazaryeri"
              select-label="title"
              v-bind:disabled="!marketplaceConfig"
              v-bind:clearable="true"
              v-bind:option-prop="detailMarketplaceList"
              v-model="filter.marketplace"
            />
          </div>
          <!-- Marketplace -->

          <!-- Price -->
          <div class="d-block mb-3">
            <div class="form-row align-items-center">
              <div class="col-12 col-lg-3">
                <label for="price" class="custom-label">Ürün Fiyatı</label>
              </div>
              <div class="col-12 col-lg-6">
                <div class="row align-items-center">
                  <div class="col">
                    <WePriceInput
                      v-bind:small-size="true"
                      class="mb-0"
                      placeholder="0,00"
                      v-model="filter.start_price"
                      ref="startPrice"
                    />
                  </div>
                  <span>-</span>
                  <div class="col">
                    <WePriceInput
                      v-bind:small-size="true"
                      class="mb-0"
                      placeholder="0,00"
                      v-model="filter.end_price"
                      ref="endPrice"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Price -->
        </div>
        <div class="col-12 col-lg-6">
          <!-- SKU -->
          <div class="d-block mb-3">
            <WeRowInput
              size="sm"
              name="sku_no"
              label="Stok Kodu"
              placeholder="Stok Kodu"
              v-model="filter.sku_no"
            />
          </div>
          <!-- SKU -->

          <!-- Barcode -->
          <div class="d-block mb-3">
            <WeRowInput
              size="sm"
              name="barcode"
              label="Barkod"
              placeholder="Barkod"
              v-model="filter.barcode"
            />
          </div>
          <!-- Barcode -->

          <div class="d-block mb-3">
            <div class="form-row align-items-center">
              <div class="col-12 col-lg-3">
                <label for="price" class="custom-label">Stok Miktarı</label>
              </div>
              <div class="col-12 col-lg-6">
                <div class="row">
                  <div class="col">
                    <WeRowInput
                      size="sm"
                      inputClass="ml-1"
                      labelClass="display-none"
                      placeholder="Min"
                      v-bind:filter-number="true"
                      v-model="filter.min_stock"
                    />
                  </div>
                  <div class="col">
                    <WeRowInput
                      size="sm"
                      inputClass="mr-1"
                      labelClass="display-none"
                      placeholder="Max"
                      v-bind:filter-number="true"
                      v-model="filter.max_stock"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Created At -->
          <div class="d-block mb-3">
            <WeRowInput
              form="date"
              size="sm"
              label="Eklenme Tarihi"
              placeholder="Eklenme Tarihi"
              v-bind:date-range="true"
              v-model="filter.date"
            />
          </div>
          <!-- Created At -->

          <!-- Is Active -->
          <div class="d-block mb-3">
            <WeRowInput
              form="vSelect"
              size="sm"
              name="product_active"
              label="Ürün Durumu"
              placeholder="Ürün Durumu"
              v-model="filter.is_active"
              v-bind:option-prop="product_active_options"
              v-bind:clearable="true"
            />
          </div>
          <!-- Is Active -->
          
          <!-- Tax -->
          <div class="d-block mb-3">
            <div class="form-row align-items-center">
              <div class="col-12 col-lg-3">
                <label for="price" class="custom-label">KDV</label>
              </div>
              <div class="col-12 col-lg-6">
                <div class="row">
                  <div class="col">
                    <WeRowInput
                      size="sm"
                      inputClass="ml-1"
                      labelClass="display-none"
                      placeholder="Min"
                      v-bind:filter-number="true"
                      v-model="filter.start_tax"
                    />
                  </div>
                  <div class="col">
                    <WeRowInput
                      size="sm"
                      inputClass="mr-1"
                      labelClass="display-none"
                      placeholder="Max"
                      v-bind:filter-number="true"
                      v-model="filter.end_tax"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Tax -->
          
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <span class="btn btn-primary btn-sm" v-on:click="getProductList"
            >Filtrele</span
          >
        </div>
        <div class="col-auto pl-0">
          <span class="btn btn-danger btn-sm" v-on:click="clearFilter"
            >Temizle</span
          >
        </div>
        <!---->

        
      </div>
    </WeCard>
    <!-- ./Filter -->

    <!-- Update -->
    <WeCard class="mb-3">
      <h5 class="custom-card-title">
        <i class="fas fa-sync mr-2"></i>
        <span>Güncelleme</span>
      </h5>
      <hr />
      <div class="row">
        <div class="col-12 col-lg-9 col-xl-6">
          <WeCard class="alert-info mb-3" v-if="marketplaceActive">
            <i class="fas fa-info-circle mr-2"></i>
            <span
              >Sadece Pazaryerinde yayında olan ürünler güncellemeden
              etkilenir.</span
            >
          </WeCard>
          <InputGroups
            v-for="(ig, i) in getInputGroups"
            v-bind:key="i"
            v-bind:name="ig"
            v-bind:marketplace-active="marketplaceActive"
            v-bind:marketplace-sync="update.marketplace_sync.active"
            v-bind:marketplace-deactive="update.marketplace_deactive.active"
            v-bind:current-marketplace="getMarketplaceInfo"
            v-model="update[ig]"
          />

          <div class="row mb-3 align-items-center" v-if="!marketplaceActive">
            <div class="col-12 col-lg">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="supplier-status"
                  v-model="update.supplier.active"
                />
                <label
                  class="custom-control-label custom-label"
                  for="supplier-status"
                  >Tedarikçi</label
                >
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="d-block mb-1">
                <WeRowInput
                  form="vSelect"
                  size="sm"
                  inputClass="col-12 col-lg pl-lg-0"
                  name="supplier"
                  labelClass="display-none"
                  v-bind:option-prop="suppliers"
                  v-bind:disabled="!update.supplier.active"
                  v-model="update.supplier.value"
                />
              </div>
            </div>
            <div class="col-12 col-lg pl-lg-0">
              <div class="d-block mb-1">
                <WeRowInput
                  form="vSelect"
                  size="sm"
                  inputClass="col-12 col-lg pl-lg-0"
                  name="supplier"
                  labelClass="display-none"
                  v-bind:disabled="!update.supplier.active"
                  v-bind:option-prop="defaultOperation"
                  v-model="update.supplier.operation"
                />
              </div>
            </div>
          </div>
          <template v-if="marketplaceActive">
            <!-- Extra Cost -->
            <div class="row mb-3 align-items-center">
              <div class="col-12 col-lg-3">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="extra_cost"
                    v-bind:disabled="update.marketplace_deactive.active"
                    v-model="update.extra_cost.active"
                  />
                  <label
                    class="custom-control-label custom-label"
                    for="extra_cost"
                    >İlave Maliyet</label
                  >
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <WeInput
                  class="mb-0"
                  prepend="₺"
                  v-bind:currency="true"
                  v-bind:disabled="
                    !update.extra_cost.active ||
                    update.marketplace_deactive.active
                  "
                  v-bind:filter-number="true"
                  v-bind:small-size="true"
                  v-model="update.extra_cost.masked"
                  v-on:unmask="update.extra_cost.value = $event"
                />
              </div>
            </div>
            <!-- Extra Cost -->

            <!-- Marketplace Switch -->
            <WeSwitch
              label-class="custom-label no-select mt-1"
              label="Pazaryeri satış fiyatını, ürün satış fiyatına eşitle"
              v-bind:disabled="update.marketplace_deactive.active"
              v-bind:value="
                update.marketplace_deactive.active
                  ? false
                  : update.marketplace_sync.active
              "
              v-on:input="update.marketplace_sync.active = $event"
            />
            <!-- ./Marketplace Switch -->

            <!-- Marketplace Off -->
            <WeSwitch
              class="mt-4"
              label-class="custom-label no-select"
              v-bind:label="
                getMarketplaceInfo.title + ' pazaryeri satışını kapat'
              "
              v-model="update.marketplace_deactive.active"
              v-on:change="closeMarketplaceSelling"
            />
            <!-- ./Marketplace Off -->
          </template>

          <!-- Discount Amount -->
          <div class="row mb-3 align-items-center" v-if="!marketplaceActive">
            <div class="col-12 col-lg-3">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="discount-status"
                  v-model="update.discount_rate.active"
                />
                <label
                  class="custom-control-label custom-label"
                  for="discount-status"
                  >İndirim Oranı</label
                >
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <WeInput
                class="mb-0"
                prepend="%"
                v-bind:disabled="!update.discount_rate.active"
                v-bind:filter-number="true"
                v-bind:small-size="true"
                v-model="update.discount_rate.value"
              />
            </div>
          </div>
          <!-- Discount Amount -->

          <!-- Tax -->
          <div class="row mb-3 align-items-center" v-if="!marketplaceActive">
            <div class="col-12 col-lg-3">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="tax-status"
                  v-model="update.tax.active"
                />
                <label
                  class="custom-control-label custom-label"
                  for="tax-status"
                  >KDV</label
                >
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <WeInput
                class="mb-0"
                prepend="%"
                v-bind:disabled="!update.tax.active"
                v-bind:filter-number="true"
                v-bind:small-size="true"
                v-model="update.tax.value"
              />
            </div>
          </div>
          <!-- Tax -->

          <!-- Profit -->
          <div class="row mb-3 align-items-center" v-if="!marketplaceActive">
            <div class="col-12 col-lg-3">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="profit"
                  v-model="update.profit.active"
                />
                <label class="custom-control-label custom-label" for="profit"
                  >Kâr</label
                >
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <WeInput
                class="mb-0"
                prepend="%"
                v-bind:disabled="!update.profit.active"
                v-bind:filter-number="true"
                v-bind:small-size="true"
                v-model="update.profit.value"
              />
            </div>
          </div>
          <!-- Profit -->

          <!-- Preparation Time -->
          <div class="row mb-3 align-items-center" v-if="!marketplaceActive">
            <div class="col-12 col-lg-3">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="preparation-time"
                  v-model="update.preparation_time.active"
                />
                <label
                  class="custom-control-label custom-label"
                  for="preparation-time"
                  >Hazırlanma Süresi</label
                >
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <WeInput
                class="mb-0"
                append="Gün"
                v-bind:disabled="!update.preparation_time.active"
                v-bind:filter-number="true"
                v-bind:small-size="true"
                v-model="update.preparation_time.value"
              />
            </div>
          </div>
          <!-- Preparation Time -->
        </div>
        <div class="col-12 col-lg-6 col-xl-6">
          <!-- Is Active -->
          <div class="row mb-3 align-items-center" v-if="!marketplaceActive">
            <div class="col-12 col-lg">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="is-active-status"
                  v-model="update.is_active.active"
                />
                <label
                  class="custom-control-label custom-label"
                  for="is-active-status"
                  >Ürün Durumu</label
                >
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <WeSwitch
                v-model="update.is_active.value"
                v-bind:disabled="!update.is_active.active"
                v-bind:show-status="true"
              />
            </div>
          </div>
          <!-- Is Active -->

          <!-- Is New Product -->
          <div class="row mb-3 align-items-center" v-if="!marketplaceActive">
            <div class="col-12 col-lg">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="is-is_new_product-status"
                  v-model="update.is_new_product.active"
                />
                <label
                  class="custom-control-label custom-label"
                  for="is-is_new_product-status"
                  >Yeni Ürün</label
                >
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <WeSwitch
                v-model="update.is_new_product.value"
                v-bind:disabled="!update.is_new_product.active"
                v-bind:show-status="true"
              />
            </div>
          </div>
          <!-- Is New Product -->

          <!-- Is Store Product -->
          <div class="row mb-3 align-items-center" v-if="!marketplaceActive">
            <div class="col-12 col-lg">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="is-is_store_product-status"
                  v-model="update.is_store_product.active"
                />
                <label
                  class="custom-control-label custom-label"
                  for="is-is_store_product-status"
                  >Mağaza Ürünü</label
                >
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <WeSwitch
                v-model="update.is_store_product.value"
                v-bind:disabled="!update.is_store_product.active"
                v-bind:show-status="true"
              />
            </div>
          </div>
          <!-- Is Store Product -->

          <!-- Is Google Merchant -->
          <div class="row mb-3 align-items-center" v-if="!marketplaceActive">
            <div class="col-12 col-lg">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="is-show_in_merchant_xml-status"
                  v-model="update.show_in_merchant_xml.active"
                />
                <label
                  class="custom-control-label custom-label"
                  for="is-show_in_merchant_xml-status"
                  >Google Merchant</label
                >
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <WeSwitch
                v-model="update.show_in_merchant_xml.value"
                v-bind:disabled="!update.show_in_merchant_xml.active"
                v-bind:show-status="true"
              />
            </div>
          </div>
          <!-- Is Google Merchant -->

          <!-- Is Free Cargo -->
          <div class="row mb-3 align-items-center" v-if="!marketplaceActive">
            <div class="col-12 col-lg">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="is-free-cargo-status"
                  v-model="update.is_free_cargo.active"
                />
                <label
                  class="custom-control-label custom-label"
                  for="is-free-cargo-status"
                  >Ücretsiz Kargo</label
                >
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <WeSwitch
                v-model="update.is_free_cargo.value"
                v-bind:disabled="!update.is_free_cargo.active"
                v-bind:show-status="true"
              />
            </div>
          </div>
          <!-- Is Google Merchant -->

          <!-- Is Google Merchant -->
          <div class="row mb-3 align-items-center" v-if="!marketplaceActive">
            <div class="col-12 col-lg">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="is-use_installment-status"
                  v-model="update.use_installment.active"
                />
                <label
                  class="custom-control-label custom-label"
                  for="is-use_installment-status"
                  >Taksit Seçenekleri</label
                >
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <WeSwitch
                v-model="update.use_installment.value"
                v-bind:disabled="!update.use_installment.active"
                v-bind:show-status="true"
              />
            </div>
          </div>
          <!-- Is Google Merchant -->
        </div>
      </div>
    </WeCard>
    <!-- ./Update -->

    <!-- Product List -->
    <WeCard v-if="filter_active">
      <h5 class="custom-cardit-title">
        <i class="fas fa-boxes mr-3"></i>Güncellenecek Ürünler
      </h5>
      <hr />
      <WeTable
        v-bind:index="false"
        v-bind:data="product.list"
        v-bind:allowSortIndex="false"
        v-bind:actions="actions"
        v-bind:columns="columns"
        v-bind:ajax="true"
        v-bind:loading="loading"
        v-bind:table-result="tableResult"
        v-on:make-request="updateProductList"
        v-on:on-action="onAction"
        ref="productTable"
      />
    </WeCard>
    <!-- ./Product List -->
  </WeContainer>
</template>
<script>
import { mapActions, mapState } from "vuex";
import InputGroups from "./components/InputGroups/Index";

export default {
  name: "BulkUpdate",
  components: {
    InputGroups,
  },
  data() {
    return {
      submit: false,
      ready: false,
      loading: false,
      filter_active: false,
      filter: {
        brand: null,
        category: null,
        supplier: null,
        is_active: null,
        name: null,
        max_stock: null,
        barcode: null,
        sku_no: null,
        date: null,
        min_stock: null,
        start_price: helper.setPrice(0),
        end_price: helper.setPrice(0),
        marketplace: null,
      },
      defaultOperation: [
        {
          id: "update",
          name: "Güncelle",
        },
        {
          id: "remove",
          name: "Kaldır",
        },
      ],
      copyFilter: {},
      inputGroupData: [
        "quantity",
        "category",
        "price",
        "discount_price",
        "purchase_price",
      ],
      product_active_options: [
        {
          id: 1,
          name: "Aktif",
        },
        {
          id: 0,
          name: "Pasif",
        },
      ],
      tableResult: {
        item_count: 1,
        current_page: 1,
        total_page: 1,
        total_items: 1,
      },
      update: {
        // Stok Adedi
        quantity: {
          active: false,
          value: "0",
          operation: "increase",
        },
        price: {
          active: false,
          operation: "increase",
          operation_type: "price",
          value: "0",
          disabled: false,
        },
        extra_cost: {
          active: false,
          value: 0,
          masked: "0,00",
        },
        marketplace_sync: {
          active: false,
        },
        marketplace_deactive: {
          active: false,
        },
        purchase_price: {
          active: false,
          operation: "increase",
          operation_type: "price",
          value: "0",
        },
        discount_price: {
          active: false,
          operation: "increase",
          operation_type: "price",
          value: "0",
        },
        discount_rate: {
          active: false,
          value: "0",
        },
        supplier: {
          active: false,
          operation: "update",
          operation_type: "supplier",
          value: null,
        },
        tax: {
          active: false,
          value: "0",
        },
        profit: {
          active: false,
          value: "0",
        },
        preparation_time: {
          active: false,
          value: "0",
        },
        is_active: {
          active: false,
          value: false, 
        },
        use_installment: {
          active: false,
          value: false, 
        },
        show_in_merchant_xml: {
          active: false,
          value: false, 
        },
         is_free_cargo: {
          active: false,
          value: false, 
        },
        is_new_product: {
          active: false,
          value: false, 
        },
        is_store_product: {
          active: false,
          value: false, 
        },
        category: {
          active: false,
          operation: "equal",
          operation_type: "category",
          value: [],
        },
      },
      columns: [
        {
          name: "sku_no",
          th: "Stok Kodu",
          type: "string",
          width: "15%",
        },
        { name: "name", th: "Ürün Adı", type: "string" },
        { name: "category", th: "Kategori", type: "string" },
        {
          name: "_quantity",
          th: "Miktar",
          type: "string",
          width: "10%",
        },
        {
          name: "tax",
          th: "KDV",
          type: "string",
          filterNumber: true,
          width: "10%",
        },
        {
          name: "price",
          th: "Fiyat",
          type: "currency",
          width: "10%",
        },
        {
          name: "discount_price",
          th: "İndirimli Fiyat",
          type: "currency",
          width: "10%",
        },
      ],
      actions: [
        {
          icon: "fas fa-eye",
          class: "btn-outline-indigo",
          action: "detail",
          tooltip: "Detay Görüntüle",
        },
        {
          icon: "fas fa-globe",
          class: "btn-outline-secondary ml-2",
          action: "visit",
          tooltip: "Sitede Görüntüle",
        },
      ],
    };
  },
  methods: {
    ...mapActions("product", ["getList", "bulkUpdate"]),
    submitForm() {
      if (!this.checkPermission) {
        permission.alert(this);
      } else {
        if (this.product.list && this.product.list.length) {
          this.$swal({
            title: "İşlemi Onaylıyor musunuz ?",
            html: `<b>${this.tableResult.total_items}</b> adet ürün güncellenecek. Bu işlem geri alınamaz.`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "İptal",
            confirmButtonText: "Güncelle",
          }).then((confirm) => {
            if (confirm.isConfirmed) {
              this.makeRequest();
            }
          });
        } else {
          this.$swal({
            title: "Geçersiz İşlem",
            text: "Lütfen Güncellenecek ürünleri filtreleyin",
            icon: "error",
          });
        }
      }
    },
    getOperations(type = "supplier") {
      switch (type) {
        case "supplier":
          return this.defaultOperation;
      }
    },
    getFilter() {
      let copy = this.copyFilter;
      if (!Object.keys(copy).length) {
        return;
      }

      if (copy.start_price && copy.start_price.hasOwnProperty("unmask")) {
        copy.start_price = copy.start_price.unmask || null;
      }
      if (copy.end_price && copy.end_price.hasOwnProperty("unmask")) {
        copy.end_price = copy.end_price.unmask || null;
      }
      copy.date = [];

      if (this.filter.date && this.filter.date.start && this.filter.date.end) {
        Object.entries(this.filter.date).forEach(([key, value]) => {
          if (value) {
            copy.date.push(value);
          }
        });
      }

      return copy;
    },
    onAction(data) {
      const siteUrl = this.config["site.url"];
      const product = data.row;
      let productUrl = null;

      switch (data.key) {
        case "detail":
          productUrl = this.$router.resolve({
            path: `/products/detail/${product.id}`,
          }).href;
          break;

        default:
          productUrl = `${siteUrl}/urun/${product.slug}`;
          break;
      }

      if (productUrl) {
        window.open(productUrl, "_blank");
      }
    },
    makeRequest() {
      this.submit = true;
      let updateKeys = helper.clone(this.update);
      const requestData = {};
      this.copyFilter = helper.clone(this.filter);

      requestData.filter = this.getFilter();

      let is_keys = [
        "is_active",
        "is_store_product",
        "is_new_product",
        "show_in_merchant_xml",
        'is_free_cargo',
        "use_installment",
      ];

      Object.entries(updateKeys).forEach(([key, item]) => {
        if (item.active && !item.disabled) {
          requestData[key] = {};
          requestData[key].value = item.value;

          if (is_keys.find((A) => A == key)) {
            requestData[key].value = item.value ? 1 : 0;
          }

          if (key == "marketplace_sync" || key == "marketplace_deactive") {
            requestData[key].value = item.active;
          }

          if (item.operation) {
            requestData[key].operation = item.operation;

            if (item.hasOwnProperty("operation_type")) {
              requestData[key].operation_type = item.operation_type;
            }
          }
        }
      });

      this.bulkUpdate({
        request: requestData,
        onSuccess: (result) => {
          this.submit = false;

          this.$swal({
            title: "İşlem Başarılı",
            text: "Güncelleme işlemi sıraya alındı. Tamamlandığında mail adresinize bildirim gönderilecektir.",
            icon: "success",
            confirmButtonText: "Tamam",
            onClose: () => this.updateProductList(),
          });
        },
        onError: () => {
          this.submit = false;
        },
      });
    },
    clearList() {
      this.product.list = [];
    },
    getProductList() {
      this.filter_active = false;
      this.copyFilter = helper.clone(this.filter);
      this.updateProductList();
    },
    clearFilter() {
      this.copyFilter = {};
      helper.clearItem(this.filter);
      this.filter.start_price = helper.setPrice(null);
      this.filter.end_price = helper.setPrice(null);
      this.clearList();
    },
    updateProductList(requestData = null) {
      this.copyFilter = helper.clone(this.filter);

      this.filter_active = true;

      requestData = requestData || {
        page: 1,
        limit: 25,
      };

      this.loading = true;

      this.getList({
        filter: this.getFilter(),
        table: requestData,
        onSuccess: (result) => {
          helper.copy(result, this.tableResult, ["items", "status"]);
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
    findMarketplace(marketplaceId) {
      return this.marketplaces.find((mp) => mp.id == marketplaceId);
    },
    closeMarketplaceSelling(checked) {
      if (checked) {
        this.$swal({
          title: `Ürünler ${this.getMarketplaceInfo.title} pazaryerinde satışa kapatılacaktır !`,
          icon: "warning",
          showCancelButton: false,
        });
      }
    },
  },
  computed: {
    ...mapState({
      product: (state) => state.product,
      marketplaces: (state) => state.marketplace.list,
      category: (state) => state.category.list,
      suppliers: (state) => state.supplier.list,
      config: (state) => state.session.config,
    }),
    checkPermission() {
      return permission.check("product", "u");
    },
    detailMarketplaceList() {
      let marketplaceList = this.marketplaces;
      let eCommerce = {
        id: "e-commerce",
        title: "Pazaryerinde Olmayanlar",
      };

      const result = [eCommerce, ...marketplaceList];
      return result;
    },
    marketplaceConfig() {
      const integrationKey = this.config["site.marketplace_integration"];
      return integrationKey == "1" || integrationKey == 1;
    },
    marketplaceActive() {
      return (
        this.marketplaceConfig &&
        this.filter.marketplace &&
        this.filter.marketplace !== "e-commerce"
      );
    },
    getMarketplaceInfo() {
      return this.filter.marketplace
        ? this.findMarketplace(this.filter.marketplace)
        : null;
    },
    getInputGroups() {
      let result = helper.clone(this.inputGroupData);
      if (this.marketplaceActive) {
        result = result.filter((item) => {
          const marketplace = this.getMarketplaceInfo;
          let hiddenKeys = ["category", "purchase_price"];
          if (
            marketplace &&
            marketplace.name == "hepsiburada" &&
            !hiddenKeys["discount_price"]
          ) {
            hiddenKeys.push("discount_price");
          }
          return !hiddenKeys.includes(item);
        });
      }

      return result;
    },
    categoryList() {
      let categoryList = this.category;
      const result = [...categoryList];
      result.push({ id: -1, name: "Kategorisiz" });
      return result;
    },
    submitDisabled() {
      let result = true;

      if (this.filter_active) {
        if (this.product.list.length > 0) {
          const updateActivation = _.pickBy(this.update, (value, key) => {
            return value && value.active == true;
          });
          result = Object.keys(updateActivation).length == 0;
        }
      }

      return result;
    },
  },
  mounted() {
    this.clearList();
    this.$store.dispatch("marketplace/getList");
    this.$store.dispatch("supplier/getList", "");
    this.$store.dispatch("category/getList", "");
  },
  watch: {
    "filter.marketplace": {
      handler: function () {
        this.update.marketplace_sync.active = false;
        this.update.marketplace_deactive.active = false;
      },
    },
  },
};
</script>
