<template>
  <div class="row mb-3 align-items-center">
    <div class="col-12 col-lg-3">
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          class="custom-control-input"
          v-bind:disabled="getDisabled"
          v-bind:id="name + '-status'"
          v-model="computedData.active"
        />
        <label
          class="custom-control-label custom-label"
          v-bind:for="name + '-status'"
          >{{ labelName }}</label
        >
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="row align-items-center">
        <div class="col-12 col-lg mb-lg-0 mb-2">
          <WeInput
            v-if="name !== 'category'"
            class="mb-0"
            v-bind:disabled="
              (!computedData.active && !computedData.disabled) ||
              isOperationDeactive
            "
            v-bind:filter-number="true"
            v-bind:decimal="name !== 'quantity'"
            v-bind:small-size="true"
            v-model="computedData.value"
          >
            <div slot="prepend" class="input-group-text">
              <i v-bind:class="getIcon"></i>
            </div>
          </WeInput>
          <!-- Category -->
          <WeRowInput
            v-else
            form="searchSelect"
            size="sm"
            name="category"
            label="Kategori"
            placeholder="Kategori"
            label-class="d-none"
            input-class="col"
            v-bind:disabled="!computedData.active"
            v-bind:multiple="true"
            v-bind:close-on-select="false"
            v-model="computedData.value"
          />
          <!-- Category -->
        </div>
        <div
          class="col-12 col-lg pl-lg-0 mb-2 mb-lg-0"
          v-if="name !== 'quantity' && name !== 'category'"
        >
          <v-select
            class="custom-v-select-sm"
            v-bind:options="getOperationTypes"
            v-model="computedData.operation_type"
            label="name"
            v-bind:disabled="!computedData.active || isOperationDeactive"
            v-bind:reduce="(o) => o.id"
            v-bind:clearable="false"
          ></v-select>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg pl-lg-0">
      <v-select
        class="custom-v-select-sm"
        v-bind:options="getOperations"
        v-model="computedData.operation"
        label="name"
        v-bind:disabled="!computedData.active"
        v-bind:reduce="(o) => o.id"
        v-bind:clearable="false"
      ></v-select>
    </div>
  </div>
</template>
<script>
export default {
  name: "InputGroups",
  props: {
    data: null,
    name: {
      default: null,
    },
    marketplaceActive: {
      default: false,
    },
    marketplaceDeactive: {
      default: false,
    },
    marketplaceSync: {
      default: false,
    },
    currentMarketplace: {
      default: null,
    },
  },
  model: {
    prop: "data",
  },
  data() {
    return {
      names: {
        quantity: "Stok Miktarı",
        price: "Satış Fiyatı",
        discount_price: "İndirimli Fiyat",
        purchase_price: "Alış Fiyatı",
        category: "Kategori",
      },
      icons: {
        increase: "fas fa-plus",
        decrease: "fas fa-minus",
        equal: "fas fa-equals",
        deactive: "fas fa-ban",
      },
      operations: [
        {
          id: "increase",
          name: "Artır",
        },
        {
          id: "decrease",
          name: "Azalt",
        },
        {
          id: "equal",
          name: "Olarak Güncelle",
        },
      ],
      categoryOperations: [
        {
          id: "add",
          name: "Ekle",
        },
        {
          id: "equal",
          name: "Olarak Güncelle",
        },
         {
          id: "remove",
          name: "Kaldır",
        },
      ],
      operationTypes: [
        {
          id: "price",
          name: "Tutar",
        },
        {
          id: "percent",
          name: "Yüzde",
        },
      ],
    };
  },
  computed: {
    computedData() {
      return this.data;
    },
    labelName() {
      const names = this.names;
      const currentName = this.name;
      let result = null;

      if (this.marketplaceActive) {
        switch (currentName) {
          case "price":
            result = "Piyasa Fiyatı (Üstü Çizili Fiyat)";
            if (this.currentMarketplace.name == "hepsiburada") {
              result = "Hepsiburada Satış Fiyatı";
            }
            break;

          case "discount_price":
            result = `${this.currentMarketplace.title} Satış Fiyatı`;
            break;

          default:
            result = names[currentName];
            break;
        }
      } else {
        result = names[currentName];
      }

      return result;
    },
    getOperations() {
      let operations = helper.clone(this.operations);
      if (this.name == "category") operations = this.categoryOperations;

      if (this.computedData.operation_type == "percent") {
        operations.splice(2, 1);
        this.computedData.operation = "increase";
      }

      if (this.marketplaceActive) {
        if (this.name == "price") {
          if (this.currentMarketplace.name !== "hepsiburada") {
            operations.push({
              id: "deactive",
              name: "Kullanma",
            });
          } else {
            this.computedData.operation = "increase";
          }
        }
      }

      return operations;
    },
    getOperationTypes() {
      let operationTypes = helper.clone(this.operationTypes);

      if (this.marketplaceActive) {
        operationTypes.splice(0, 1);
        this.computedData.operation_type = "percent";
      }

      return operationTypes;
    },
    getIcon() {
      let result = "";
      result = this.icons[this.computedData.operation];
      if (!result) result = "fas fa-plus text-success";

      if (this.computedData.active) {
        switch (this.computedData.operation) {
          case "increase":
            result += " text-success";
            break;

          case "decrease":
            result += " text-danger";
            break;

          case "equal":
            result += " text-dark";
            break;
        }
      }
      return result;
    },
    getDisabled() {
      let result =
        ((this.name == "price" || this.name == "discount_price") &&
          this.marketplaceActive &&
          this.marketplaceSync) ||
        this.marketplaceDeactive;
      if (result) {
        this.computedData.active = false;
        this.computedData.value = 0;
      }
      return result;
    },
    isOperationDeactive() {
      return this.computedData.operation == "deactive";
    },
  },
  watch: {
    currentMarketplace: {
      handler: function () {
        if (["price", "quantity"].includes(this.name)) {
          this.data.active = false;
          this.data.value = "0";
        }
      },
    },
  },
};
</script>